import React from "react";

function PreviousIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="182"
      height="39"
      fill="none"
      viewBox="0 0 182 39"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.5 1l-20 17 20 20M180 18H3"
      ></path>
    </svg>
  );
}

export default PreviousIcon;
